@import '~bootstrap/dist/css/bootstrap-grid.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'libs/core/src/styles';

html {
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;

  .main-content {
    max-width: 600px !important;

    margin: 0 auto;
  }
}

.hide-activecampaign {
  ._form_1 {
    display: none;
  }
}
